import {
  Row,
  Col,
  Card,
  Button,
  Skeleton,
  Avatar,
  Image,
  Tooltip,
  Table,
  Tag,
  Modal,
} from "antd";

import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import useRequest from "../../../hooks/useRequest";
import { ShowToast, Severty } from "../../../helper/toast";
import apiPath from "../../../constants/apiPath";
import { Badge } from "antd";
import moment from "moment-timezone";
import DeleteModal from "../../../components/DeleteModal";
import AddAvailability from "./AddAvailability";
import { IstConvert } from "../../../helper/functions";

function ViewAvailability({ show, hide, data }) {
  const sectionName = "Doctor";
  const routeName = "doctor";
  const params = useParams();
  const navigate = useNavigate();
  const { request } = useRequest();
  const [loading, setLoading] = useState(false);
  const [availability, setAvailability] = useState([]);
  const [selected, setSelected] = useState();
  const [getRecord, setRecord] = useState();
  const [viewSlots, setSlots] = useState();
  const [addAvail, setShowAvail] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const api = {
    doctor: apiPath.doctor,
  };

  const fetchData = (id) => {
    request({
      url: api.doctor + "/availability/" + id,
      method: "GET",
      onSuccess: (data) => {
        setAvailability(data.data);
        setLoading(false);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const onDelete = (id) => {
    request({
      url: api.doctor + "/availability/" + id,
      method: "DELETE",
      onSuccess: () => {
        setLoading(false);
        setRefresh((prev) => !prev);
        fetchData(data);
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  useEffect(() => {
    console.log(data, 123);
    fetchData(data);
  }, [refresh]);

  const AvailabilityColumn = [
    {
      title: "S. No",
      dataIndex: "index",
      key: "index",
      render: (value, item, index) => `${index + 1}`,
    },
    {
      title: "Availability Day",
      dataIndex: "availability",
      key: "availability",
      render: (_, { _id, availability_day }) =>
        // <Image width={50} src={image ? apiPath.assetURL + image : notfound} />
        {
          return <div onClick={() => handleSlots(_id)}>{availability_day}</div>;
        },
    },
    {
      title: "Availability Time",
      dataIndex: "availability",
      key: "availability",
      render: (_, { availability_time_to, availability_time_from }) => {
        const IST_availability_time_from = moment(
          availability_time_from,
        ).format("hh:mm a");
        const IST_availability_time_to =
          moment(availability_time_to).format("hh:mm a");

        // Return JSX with IST times
        return (
          <p>
            {IST_availability_time_from} - {IST_availability_time_to}
          </p>
        );
      },
    },
    {
      title: "Appointment Status",
      key: "status",
      dataIndex: "status",
    },
    {
      title: "Added On",
      key: "appointment_date",
      dataIndex: "appointment_date",
      render: (_, { appointment_date }) => {
        return moment(appointment_date).format("DD-MMM-YYYY");
      },
    },
    {
      title: "Created On",
      key: "created_at",
      dataIndex: "created_at",
      render: (_, { created_at }) => {
        return moment(created_at).format("DD-MMM-YYYY");
      },
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        return (
          <div div className="d-flex justify-contenbt-start">
            <Tooltip title={"View Details"} color={"purple"} key={"Delete"}>
              <Button
                title=""
                className="btnStyle primary_btn"
                onClick={() => {
                  setShowDelete(true);
                  setSelected(record._id);
                }}
              >
                <i className="fa fa-trash-alt" style={{ fontSize: "14px" }}></i>
              </Button>
            </Tooltip>
          </div>
        );
      },
    },
  ];
  const SlotColumn = [
    {
      title: "slot Day",
      dataIndex: "slot",
      key: "slot",
      render: (_, { slot_day }) =>
        // <Image width={50} src={image ? apiPath.assetURL + image : notfound} />
        {
          return <p>{slot_day}</p>;
        },
    },
    {
      title: "slot Date",
      dataIndex: "slot",
      key: "slot",
      render: (_, { slot_date }) =>
        // <Image width={50} src={image ? apiPath.assetURL + image : notfound} />
        {
          return <p>{moment(slot_date).format("DD-MM-YYYY")}</p>;
        },
    },
    {
      title: "slot Time",
      dataIndex: "slot",
      key: "slot",
      render: (_, { slot_time_to, slot_time_from }) => {
        // // Convert slot times to IST
        // const IST_offset_hours = 5;
        // const IST_offset_minutes = 30;

        // // Parse original times
        // const [from_hours, from_minutes] = slot_time_from.split(":").map(Number);
        // const [to_hours, to_minutes] = slot_time_to.split(":").map(Number);

        // // Add IST offset
        // let IST_from_hours = from_hours + IST_offset_hours;
        // let IST_to_hours = to_hours + IST_offset_hours;
        // let IST_from_minutes = from_minutes + IST_offset_minutes;
        // let IST_to_minutes = to_minutes + IST_offset_minutes;

        // // Adjust if minutes exceed 60
        // if (IST_from_minutes >= 60) {
        //   IST_from_hours += 1;
        //   IST_from_minutes -= 60;
        // }
        // if (IST_to_minutes >= 60) {
        //   IST_to_hours += 1;
        //   IST_to_minutes -= 60;
        // }

        // // Adjust if hours exceed 24
        // IST_from_hours %= 24;
        // IST_to_hours %= 24;

        // // Format IST times
        const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        console.log(localTimeZone, "time zone>>>>>");
        const IST_slot_time_from = moment
          .utc(slot_time_from, "HH:mm")
          .tz(localTimeZone)
          .format("hh:mm A");

        const IST_slot_time_to = moment
          .utc(slot_time_to, "HH:mm")
          .tz(localTimeZone)
          .format("hh:mm A");

        // Return JSX with IST times
        return (
          <p>
            {IST_slot_time_from} - {IST_slot_time_to}
          </p>
        );
      },
    },
    {
      title: "Slot Status",
      key: "status",
      dataIndex: "status",
    },
  ];

  const handleSlots = (id) => {
    fetchAdditionalData(id);
  };

  const addAvailibility = () => {
    setShowAvail(true);
    console.log("set>>>>>>>..");
    // hide()
  };

  const fetchAdditionalData = (record) => {
    console.log(record, 100);
    request({
      url: api.doctor + "/slots/" + record,
      method: "GET",
      onSuccess: (data) => {
        console.log(data.slots);
        // setLoading(false);
        // setRefresh((prev) => !prev);
        setRecord(data.slots);
        setSlots(true);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
    // Return JSX to display additional data
  };

  // const slotTable = (slots) => { return (
  //   <Table
  //   loading={loading}
  //   columns={SlotColumn}
  //   dataSource={slots}
  //   pagination={true}
  //   className="ant-border-space"
  // />
  // );}
  // useEffect(()=>{
  //   // console.log(getRecord,"ooo")
  //   return <Table  columns={SlotColumn} dataSource={getRecord} pagination={false} />;
  // }, [getRecord])

  return (
    <>
      <Modal
        open={show}
        width={1050}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="tab_modal"
        footer={null}
      >
        <Card className="mt-3" title="Appointments">
          <Row gutter={16}>
            <div className="float-end mt-3 w-100 text-right">
              <Button className="primary_btn btnStyle" onClick={() => hide()}>
                Back
              </Button>
              <Button
                className="primary_btn btnStyle"
                onClick={addAvailibility}
              >
                Add
              </Button>
            </div>
          </Row>
          <div className="tabled mt-3">
            <Row gutter={[24, 0]}>
              <Col xs={24} xl={24}>
                <div className="table-responsive customPagination">
                  <Table
                    loading={loading}
                    columns={AvailabilityColumn}
                    dataSource={availability}
                    pagination={true}
                    className="ant-border-space"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Card>
      </Modal>
      {/* Conditionally render AddAvailability component */}

      {viewSlots && (
        <Modal
          open={viewSlots}
          close={() => setSlots(false)}
          width={1050}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="tab_modal"
          footer={null}
        >
          <Card className="mt-3" title="Slots">
            <Row gutter={16}>
              <div className="float-end mt-3 w-100 text-right">
                <Button className="primary_btn btnStyle" onClick={() => hide()}>
                  Back
                </Button>
              </div>
            </Row>
            <div className="tabled mt-3">
              <Row gutter={[24, 0]}>
                <Col xs={24} xl={24}>
                  <div className="table-responsive customPagination">
                    <Table
                      loading={loading}
                      columns={SlotColumn}
                      dataSource={getRecord}
                      pagination={true}
                      className="ant-border-space"
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </Modal>
      )}

      {addAvail && (
        <AddAvailability
          section={"Add Availability"}
          api={api}
          show={addAvail}
          hide={() => {
            setSelected();
            setShowAvail(false);
            //show();
          }}
          data={data}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      {showDelete && (
        <DeleteModal
          title={"Delete User"}
          subtitle={`Are you sure you want to Delete Availability?`}
          show={showDelete}
          hide={() => {
            setShowDelete(false);
            setSelected();
          }}
          onOk={() => onDelete(selected)}
        />
      )}
    </>
  );
}

export default ViewAvailability;

import { Row, Col, Card, Table, DatePicker, Tag, Button,Tooltip } from "antd";
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";

import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import moment from "moment";
import ShowTotal from "../../components/ShowTotal";
import apiPath from "../../constants/apiPath";
import * as XLSX from "xlsx";
import { calculateAge } from "../../helper/functions";

import { AppStateContext, useAppContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
const { RangePicker } = DatePicker;

function UserDevices() {
  
  const sectionName = "User LoggedIn Devices";
  const routeName = "userDevices";
  const params = useParams();
  const navigate = useNavigate();
  const api = {
    list: apiPath.userdevices,
  };

  const heading = lang("User LoggedIn Devices") + " " + lang("management");
  const { setPageHeading } = useContext(AppStateContext);
  const { request } = useRequest();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(0);
  const [days, setDays] = useState('');
  const [name, setName] = useState('')
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });

  const columns = [
   
   
    {
      title: "Last Logged In",
      key: "login_time",
      dataIndex: "login_time",
      render: (_, { login_time }) => {
        return login_time
          ? moment(login_time).format("DD-MMM-YYYY HH:mm A")
          : "-";
      },
    },
    {
      title: "Last Logged Out",
      key: "logout_time",
      dataIndex: "logout_time",
      render: (_, { logout_time }) => {
        return logout_time
          ? moment(logout_time).format("DD-MMM-YYYY HH:mm A")
          : "-";
      },
    },
    {
      title: "Device Type",
      dataIndex: "device_type",
      key: "device_type",
      render: (_, { device_type }) => {
        return device_type ? (
          <Tag color={"green"}>
            <span className="cap">{device_type}</span>
          </Tag>
        ) : (
          "-"
        );
      },
    },
    {
        title: "Action",
        fixed: "right",
        key: "action",
        render: (_, record) => {
          return (
            <div div className="d-flex justify-contenbt-start">
              <>
    
                <Tooltip
                  title={"Activity Log"}
                  color={"purple"}
                  key={"activity user"}
                >
                  <Button
                    className="btnStyle primary_btn"
                    onClick={(e) => activity(record._id)}
                  >
                    <i className="fas fa-light fa-history"></i>
                  </Button>
                </Tooltip>
               
              </>
            </div>
          );
        },
      },
  ];
const activity = (id) => {
    navigate(`/user/activity/${id}`);
    setPageHeading("User Activity");
  };
  useEffect(() => {
    setLoading(true);
    fetchData(params.id, pagination);
  }, [refresh, startDate, endDate]);

  const fetchData = (userId, pagination, filters) => {
    request({
      url:
        api.list +
        `/${userId}` +
        `?page=${pagination ? pagination.current : 1}&limit=${
          pagination ? pagination.pageSize : 10
        }&start_date=${startDate ? startDate : ""}&end_date=${
          endDate ? endDate : ""
        }`,
      method: "GET",
      onSuccess: (data) => {
        setLoading(false);
        setDays(data.data.inactiveDays)
        setList(data.data.data);
        setName(data.data.list.docs[0].user.name? data.data.list.docs[0].user.name:`${ data.data.list.docs[0].user.firstName} ${ data.data.list.docs[0].user.lastName}` );

        setDays(data.data.inactiveDays)
        setPagination((prev) => ({
          current: pagination.current,
          total: data.data.list.totalDocs,
        }));
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    fetchData(pagination, filters);
  };
console.log("isActive days work or not",days)
  const handleChangeDate = (e) => {
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"));
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"));
    } else {
      setStartDate();
      setEndDate();
    }
  };
  const handleExport = () => {
    const data =
      list &&
      list.length > 0 &&
      list.map((row, index) => (
        {
          "S.No.": index +1,
          "User Id": row?.user?.uhid,
          "Name": row?.user?.name?.split(" ")[0] +" "+ row?.user?.name?.split(" ")[1],          
          "Device Type": row?.device_type?row?.device_type: "-",
          "Active on":row?.active_on? moment(row?.active_on).format("DD-MMM-YYYY HH:mm A"): "-",
          "InActive On":row?.inactive_on? moment(row?.inactive_on).format("DD-MMM-YYYY HH:mm A"): "-",
                  }
        // alert(row.languageId.name)
      ));

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "User Data");
    XLSX.writeFile(
      workbook,
      `${moment().milliseconds() +
      1000 * (moment().seconds() + 60 * 60) +
      "-access"
      }.xlsx`
    );
  };

  return (
    <>
      <div className="tabled customerMain">
     

        <Row gutter={[24, 0]}>
          <Col span={24} xl={24}>
            <Card
              bordered={false}
              className="criclebox tablespace mb-24"
              title={name.charAt(0).toLocaleUpperCase()+ name.slice(1) + " " + sectionName + " Management"}
              extra={
                <>
                  <div className="searchOuter search-topbar">
                    <RangePicker
                      disabledDate={(current) => current.isAfter(Date.now())}
                      onChange={handleChangeDate}
                    />
                    <Button className="btnStyle  primary_btn" onClick={() => handleExport()} >Export</Button>
                  </div>
                </>
              }
            >
              {/* <h4 className="text-right mb-1">
                { `${days? days: 0} Active Days` }
              </h4> */}
              <div className="table-responsive customPagination">
                <Table
                  loading={loading}
                  columns={columns}
                  dataSource={list}
                  pagination={{
                    defaultPageSize: 10,
                    responsive: true,
                    total: pagination.total,
                    showSizeChanger: false,
                    // pageSizeOptions: ["10", "20", "30", "50"],
                  }}
                  onChange={handleChange}
                  className="ant-border-space"
                />
              </div>
            </Card>
          </Col>
        </Row>
        <Button className="primary_btn btnStyle float-end" onClick={()=>  window.history.back()}>Back</Button>
      </div>
    </>
  );
}
export default UserDevices;

import React, { useEffect, useState } from "react";
import useRequest from "../../hooks/useRequest";
import { Avatar, List, Skeleton } from "antd";
import moment from "moment";
import LogoMImg from "../../assets/images/logo-black-main.png"

export const NotificationList = () => {
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const { request } = useRequest()

  const dates = {
    today: moment(new Date()).format("YYYY-MM-DD"),
    yesterday: moment(new Date().getTime() - 24 * 60 * 60 * 1000).format(
      "YYYY-MM-DD",
    ),
  };

  console.log(dates, "datedmjgfjhg");

  const fetchData = () => {
    setLoading(true)
    request({
      url:'/admin/notification/getNotification',
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        setList(data?.data)
        // setPagination(prev => ({ current: pagination?.current, total: data?.data?.length }))
      },
      onError: (error) => {
        setLoading(false)
        // ShowToast(error, Severty.ERROR)
      }
    })
    // setList(dummyNotifications);
    setLoading(false);
  };


  const readNotification = () => {
    setLoading(true)

    request({
      url:'/admin/notification/readAll',
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false)
        // setList(data?.data)
        // setPagination(prev => ({ current: pagination?.current, total: data?.data?.length }))
      },
      onError: (error) => {
        setLoading(false)
        // ShowToast(error, Severty.ERROR)
      }
    })
    // setList(dummyNotifications);
  };



  useEffect(() => {
    fetchData();
    readNotification();
  }, []);

  useEffect(() => {
    console.log(list);
  }, [list]);

  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        <>
          {Object.keys(list).map((groupedKey, index) => {
            return (
              <div className="notification-card" key={"groupItem" + index}>
                <List
                  itemLayout="horizontal"
                  dataSource={list[groupedKey]}
                  renderItem={(item, index) => (
                    <List.Item>
                      <List.Item.Meta
                        avatar={<Avatar className="notifiaction-logo-img-main" src={LogoMImg} />}
                        title={
                          <div className="notifiaction-logo-img">
                            {/* <img
                              
                              alt="Notification"
                              style={{ marginRight: '16px' }}
                              /> */}
                            <div>{item.description}</div>
                          </div>
                        }
                        
                        description={moment(item.created_at).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )}
                        />
                    </List.Item>
                  )}
                />
              </div>
            );
          })}
        </>
      )}
    </>
  );
  
  
};

import { Button, Image, Input, Table, Tag, Tooltip, DatePicker } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import ConfirmationBox from "../../components/ConfirmationBox";
import DeleteModal from "../../components/DeleteModal";
import SectionWrapper from "../../components/SectionWrapper";
import apiPath from "../../constants/apiPath";
import { AppStateContext } from "../../context/AppContext";
import lang from "../../helper/langHelper";
import { Severty, ShowToast } from "../../helper/toast";
import useDebounce from "../../hooks/useDebounce";
import useRequest from "../../hooks/useRequest";
import AddForm from "./AddForm";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import * as XLSX from "xlsx";
const { RangePicker } = DatePicker;


function Index() {
  const heading = lang("Transaction");
  const { setPageHeading, country } = useContext(AppStateContext);

  const sectionName = "Transaction";
  const routeName = "patient";
  const urlParams = new URLSearchParams(window.location.search);
  const path = urlParams.get("status");

  const api = {
    patient: apiPath.listTransaction,
    addEdit: apiPath.listPatient,
  };


  const [searchText, setSearchText] = useState("");
  const { request } = useRequest();
  const { showConfirm } = ConfirmationBox();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);

  //For Filters
  const [filter, setFilter] = useState();

  const [refresh, setRefresh] = useState(false);
  const [visible, setVisible] = useState(false);
  const [deleteModal, showDeleteModal] = useState(false);
  const [deleteAllModal, showDeleteAllModal] = useState(false);
  const [selected, setSelected] = useState();
  const [selectedIds, setSelectedIds] = useState([]);
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const debouncedSearchText = useDebounce(searchText, 300);
  const navigate = useNavigate();

  const onDelete = (id) => {
    request({
      url: api.appointmentPrice + "/" + id,
      method: "DELETE",
      onSuccess: (data) => {
        ShowToast(data.message, Severty.SUCCESS);
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const DeleteAll = () => {
    if (!selectedIds.length) return;
    request({
      url: api.appointmentPrice + "/delete-all",
      method: "POST",
      data: {
        ids: selectedIds,
      },
      onSuccess: (data) => {
        setLoading(false);
        setRefresh((prev) => !prev);
      },
      onError: (error) => {
        console.log(error);
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const columns = [
    {
      title: "S.No.",
      dataIndex: "sno",
      key: "sno",
      render: (_, __, index) => {
        return <p>{index + 1}</p>;
      }
    },
    {
      title: "Transaction ID",
      dataIndex: "uhid",
      key: "uhid",
      render: (_, { uhid, transaction_id }) => {
        return uhid ? (
          <Link to={`/${routeName}/view/${transaction_id}`}>{uhid}</Link>
        ) : (
          transaction_id
        );
      },
    },
    {
      title: "Transaction Date",
      key: "date_time_transaction",
      dataIndex: "date_time_transaction",
      render: (_, { created_at }) => {
        return moment(created_at).format("ll");
      },
    },
    {
        title: "Patients",
        key: "booked_for",
        dataIndex: "patient_id",
        // render: (patient) => patient?.name || "N/A",
        render: (_, { patient_id }) => {
          return (
            <>
              {patient_id ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                  }}
                >
                                  <span className="cap"  
                                  style={{color: "red"}}
                                  onClick={() => navigate(`/patient/view/${patient_id._id}`)}
                                  >{patient_id?.uhid}</span>
                  <span className="cap">{patient_id?.name}</span>
                  <span className="cap">{patient_id?.email}</span>
  
                  {patient_id?.mobile_number &&
                    patient_id?.country_code && (
                      <span style={{ color: "gray", fontSize: "12px" }}>
                        {patient_id?.country_code +
                          "-" +
                          patient_id?.mobile_number}
                      </span>
                    )}
                  {/* {booked_for && (
                    <Tag
                      color={
                        booked_for == "self"
                          ? "green"
                          : booked_for == "relation"
                          ? "blue"
                          : "teal"
                      }
                      key={booked_for}
                      className="cap"
                    >
                      {booked_for === "relation"
                        ? patient_details?.relationship_with_user
                        : booked_for}
                    </Tag>
                  )} */}
                </div>
              ) : (
                "-"
              )}
            </>
          );
        },
      },
    {
      title: "Appointment ID",
      dataIndex: ["appointment_id", "appointment_id"],
      key: "appointment_id",
      filterMultiple: false,
      width: 200,
      render: (_, { appointment_id }) => {
        return appointment_id && appointment_id.appointment_id 
          ? <Link to={`/appointment/view/${appointment_id._id}`}> {appointment_id.appointment_id} </Link> 
          : "";
      },
      sorter: (a, b) => {
        if (a.appointment_id && b.appointment_id) {
          return a.appointment_id.appointment_id.localeCompare(b.appointment_id.appointment_id);
        }
        return 0;
      },
    },       
    {
      title: "Order ID",
      key: "order_id",
      dataIndex: "order_id",
      render: (_, { order_id }) => {
        return order_id?.order_id;
      },
    },
    {
      title: "Order Date",
      key: "date_time_order",
      dataIndex: "date_time_order",
      render: (_, { created_at }) => {
        return moment(created_at).format("ll");
      },
    },
    // {
    //   title: "Booked By",
    //   key: "booked_by",
    //   dataIndex: "booked_by",
    // },
    // {
    //   title: "Booked For",
    //   key: "booked_for",
    //   dataIndex: "patient_id",
    //   render: (patient) => patient?.name || "N/A",
    // },
    // {
    //   title: "Doctor Name",
    //   key: "doctor_name",
    //   dataIndex: "doctor_name",
    // },
    {
      title: "Order Type",
      key: "type",
      dataIndex: "type",
      render: (text, record) => {
        return record.type ? record.type : "";
      },
    },    
    {
      title: "Order Amount",
      key: "transaction_amount",
      dataIndex: "transaction_amount",
    },
    {
      title: "Payment Mode",
      key: "payment_mod",
      dataIndex: "payment_mod",
    },
  ];
  const calculateAge = (dob) => {
    console.log("dob????????????????", dob);
    if (!dob) return null;

    const today = moment();
    const birthDate = moment(dob, "DD-MM-YYYY"); // Specify the format 'DD-MM-YYYY'
    const years = today.diff(birthDate, "years");
    birthDate.add(years, "years");
    const months = today.diff(birthDate, "months");
    birthDate.add(months, "months");
    const days = today.diff(birthDate, "days");
    console.log(years, months, days);
    return { years, months, days };
  };


  useEffect(() => {
    setLoading(true);
    fetchData(pagination, filter);
  }, [refresh, debouncedSearchText, startDate,endDate]);

  useEffect(() => {
    setPageHeading(heading);
  }, [setPageHeading]);

  const fetchData = (pagination, filters) => {
    const filterActive = filters ? filters.is_active : null;
   
console.log(filterActive,"filterActive")
    request({
      url:`${api.patient}?status=${filterActive ? filterActive : ""}&search=${debouncedSearchText}&start_date=${
          startDate ? startDate : ""
        }&end_date=${endDate ? endDate : ""}&page=${pagination ? pagination.current : 1}&pageSize=${pagination ? pagination.pageSize : 10}`,
      method: "GET",
      onSuccess: ({ data, status, total, message }) => {
        setLoading(false);
        if (status) {
          setList(total.docs);
          // setTotalRecords(data.totalDocs)

          setPagination((prev) => ({
            ...prev,
            current: pagination.current,
            total: total.totalDocs,
          }));
        }
      },
      onError: (error) => {
        setLoading(false);
        ShowToast(error, Severty.ERROR);
      },
    });
  };

  const handleChange = (pagination, filters) => {
    setFilter(filters)
    fetchData(pagination, filters);
  };
  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleChangeDate = (e) => {
    if (e != null) {
      setStartDate(moment(e[0]._d).format("YYYY-MM-DD"));
      setEndDate(moment(e[1]._d).format("YYYY-MM-DD"));
    } else {
      setStartDate();
      setEndDate();
    }
  };
  const handleExport = () => {
    console.log("transactions>>>>>>>>>");

    // Ensure 'list' is defined and has items
    const data = list && list.length > 0
      ? list.map((row, index) => ({
          "S.No.": index + 1,
          "Transaction Id": row.uhid,
          "Transaction Date": moment(row.created_at).format("ll"),
          "Patients": row.patient_id?.name ?? "N/A",
          "Appointment ID": row.appointment_id?.appointment_id ?? "N/A",
          "Order ID": row.order_id?.order_id ?? "N/A",
          "Order Date": moment(row.created_at).format("ll"),
          "Order Type": row.type,
          "Order Amount": row.transaction_amount,
          "Payment Mode": row.payment_mod,
        }))
      : []; // Set default to empty array if list is empty or undefined

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workbook, worksheet, "User Data");

    // Generate filename with current timestamp
    const fileName = `${moment().format("YYYYMMDD_HHmmss")}-access.xlsx`;
    XLSX.writeFile(workbook, fileName);
};

 
  return (
    <>
      <SectionWrapper
        cardHeading={`Transactions`}
        extra={
          <>
            <div className="w-100 text-head_right_cont">
            <RangePicker
                  style={{ height: 44 }}
                  disabledDate={(current) => current.isAfter(Date.now())}
                  onChange={handleChangeDate}
                />
                <Input.Search
                  className="searchInput"
                  placeholder="Order Id, Appointment Id, UHID"
                  onChange={onSearch}
                  allowClear
                />
                <Button
                  className="btnStyle  primary_btn"
                  onClick={() => handleExport()}
                >
                  Export
                </Button>
            </div>
          </>
        }
      >
       
        <div className="table-responsive customPagination checkBoxSrNo">
        <h4 className="text-right">TotalRecords: {list.length}</h4>
          <Table
            loading={loading}
            columns={columns}
            // rowSelection={rowSelection}
            dataSource={list}
            pagination={{
              defaultPageSize: 10,
              responsive: true,
              total: pagination.total,
              showSizeChanger: false,
              showQuickJumper: false,
              // pageSizeOptions: ["10", "20", "30", "50"],
            }}
            onChange={handleChange}
            className="ant-border-space"
          />
        </div>
      </SectionWrapper>

      {visible && (
        <AddForm
          section={sectionName}
          api={api}
          show={visible}
          hide={() => {
            setSelected();
            setVisible(false);
          }}
          data={selected}
          refresh={() => setRefresh((prev) => !prev)}
        />
      )}

      {deleteModal && (
        <DeleteModal
          title={"Delete Category"}
          subtitle={`Are you sure you want to Delete this appointment-price?`}
          show={deleteModal}
          hide={() => {
            showDeleteModal(false);
            setSelected();
          }}
          onOk={() => onDelete(selected?._id)}
        />
      )}

      {deleteAllModal && (
        <DeleteModal
          title={"Delete All Category"}
          subtitle={`Are you sure you want to Delete all appointment-price's?`}
          show={deleteAllModal}
          hide={() => {
            showDeleteAllModal(false);
            setSelectedIds([]);
          }}
          onOk={() => DeleteAll()}
        />
      )}
    </>
  );
}

export default Index;

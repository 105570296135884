import { Row, Col, Card,Input, Button,Form, Skeleton, Image, Divider } from "antd";
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import useRequest from "../../hooks/useRequest";
import { ShowToast, Severty } from "../../helper/toast";
import apiPath from "../../constants/apiPath";
import { Badge } from 'antd';
import { shortLang, longLang } from "../../config/language";
import moment from "moment";
import notfound from "../../assets/images/no-image.png";
import pdffile from "../../assets/images/pdf-file.png";
const endpoint= "https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/invent-colab-obj-bucket/";

function View() {
  const [formKey, setFormKey] = useState(0);
  const [form] = Form.useForm();

  const sectionName = "Appointment";
  const routeName = "appointments";

  const params = useParams();
  const { request } = useRequest();
  const [list, setList] = useState({});
  const [loading, setLoading] = useState(true);
  // const [follow,setFollowUp] = useState()
  // const [caseDetail,setCaseDetail] = useState({})
  const [isEditable, setIsEditable] = useState(true); // State to manage form editability


  const fetchData = (id) => {
    request({
      url: apiPath.viewAppointment + "/" + id,
      method: 'GET',
      onSuccess: (data) => {
        setLoading(false);
        setList(data.data[0]);
      },
      onError: (error) => {
        ShowToast(error, Severty.ERROR)
      }
    })
  }

  useEffect(() => {
    setLoading(true)
    fetchData(params.id)
  }, [])

  return (
    <>
      <Card className="appointmrnt-h" title={sectionName + " Details"}>
        <Row gutter={16}>
          <Col span={12} xs={24} md={24}>

            {loading ? <Skeleton active /> :
              <div className="view-main-list">

                <div className="view-inner-cls">
                  <h5>Appointment Id:</h5>
                  <h6 className="cap">{list?.appointment_id ? list?.appointment_id : '-'}</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Doctor: </h5>
                  <h6 className="cap">
                    <Image className="imagefix" src={list && list?.doctor && list.doctor.image ? list?.doctor?.image : notfound} />

                    <Link to={`/doctor/view/${list?.doctor?._id}`}>{list?.doctor ? list?.doctor.name : '-'}</Link></h6>
                </div><div className="view-inner-cls">
                  <h5>Patient:</h5>
                  <h6 className="cap" >
                    <Image className="imagefix" src={list && list?.patient_details && list.patient_details.image  ? `${list.patient_details.image}` : notfound} />

                    <Link to={`/patient/view/${list?.patient_details?._id}`}>{list?.patient_details ? list?.patient_details?.name : '-'}</Link></h6>
                </div><div className="view-inner-cls">
                  <h5>Charges:</h5>
                  <h6 className="cap">${list?.price ? list?.price : '-'}</h6>
                </div><div className="view-inner-cls">
                  <h5>Appointment Date:</h5>
                  <h6 className="cap">{list?.appointment_date? moment.utc(list?.appointment_date).format('DD-MMM-YYYY') : '-'}</h6>
                </div>
                {/* <div className="view-inner-cls">
                  <h5>Appointment Time:</h5>
                  <h6 className="cap">{list?.appointment_time ? (list?.appointment_time) : '-'}</h6>
                </div> */}
                <div className="view-inner-cls">
                  <h5>Appointment Type:</h5>
                  <h6 className="cap">{list?.appointment_type ? list?.appointment_type : '-'}</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Appointment Status:</h5>
                  <h6 className="cap">{list?.appointment_status ? list?.appointment_status : '-'}</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Appointment Category:</h5>
                  <h6 className="cap">{list?.appointment_category ? list?.appointment_category : '-'}</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Current Status:</h5>
                  <h6 className="cap">{list?.status ? list?.status : '-'}</h6>
                </div>
                <div className="view-inner-cls">
                  <h5>Created On:</h5>
                  <h6>{list?.created_at ? moment(list?.created_at).format('DD-MMM-YYYY') : '-'}</h6>
                </div>

                <div className="pdf-view">
                  {list && list?.precriptionPdf && (
                    <div className="view-inner-view">
                      <h5>Precription:</h5>
                      <div className="pdf-icons"> <i className="fas fa-file-pdf"></i></div>
                      <h6>
                        <a
                          href={list?.precriptionPdf}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Precription
                        </a>
                      </h6>
                    </div>
                  //    "otherDoc": [
                  //     "sugamya/document/file_1729657295748.pdf"
                  // ],
                  // "description": "Cold, Fever",
                  // "precriptionPdf": "sugamya/document/file_1729657806378.pdf",
                  // "advisoryNotes": "sugamya/document/file_1729657793844.pdf",
                  // "labReports": "sugamya/document/file_1729657817901.pdf",
                  )}
                  {list && list?.advisoryNotes && (
                    <div className="view-inner-view">
                      <h5>Advisory Notes:</h5>
                      <div className="pdf-icons"> <i className="far fa-file"></i></div>
                      <h6>
                        <a
                          href={endpoint+ list?.advisoryNotes}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Notes
                        </a>
                      </h6>
                    </div>
                  )}
                  {list && list?.labReports && (
                    <div className="view-inner-view">
                      <h5>Lab Reports:</h5>
                      <div className="pdf-icons"> <i className="far fa-file-alt"></i> </div>
                      <h6>
                        <a
                          href={endpoint+list?.labReports}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View Lab Reports
                        </a>
                      </h6>
                    </div>
                  )}
                  {list && list?.otherDoc.length>0 && (
                    <div className="view-inner-view">
                      <h5>Other documents:</h5>
                     
                    {list?.otherDoc?.map((doc) => 
                     <h6>
                        <a
                          href={endpoint+doc}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                           <div className="pdf-icons"> <i className="far fa-file-alt"></i> </div>
                        </a>
                      </h6>
                    ) }
                    <div>                          View Other docs
                    </div>
                    </div>
                  )}
                </div>

               {list?.followUp && <div className="main-follow-up-img">
       <div className="ant-card-head">
            <div className="ant-card-head-title">Follow-up form</div>
          </div>
      {/* <Collapse defaultActiveKey={['1']} expandIconPosition="right" > */}
      <Form key={formKey} id="create" form={form} layout="vertical">
    <Row gutter={[16, 16]}>
      <Col xs={24} md={12}>
        <Form.Item label="C/o" name="c_o">
          <Input placeholder="C/o" disabled={true} value={list?.followUp.c_o} />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <Form.Item label="Nadi" name="nadi">
          <Input placeholder="Nadi" disabled={true} value={list?.followUp.nadi} />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <Form.Item label="Mala" name="mala">
          <Input placeholder="Mala" disabled={true} value={list?.followUp.mala} />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <Form.Item label="Mutra" name="mutra">
          <Input placeholder="Mutra" disabled={true} value={list?.followUp.mutra} />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <Form.Item label="Nidra" name="nidra">
          <Input placeholder="Nidra" disabled={true} value={list?.followUp.nidra} />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <Form.Item label="Menstrual History" name="menstrual_history">
          <Input placeholder="Menstrual History" disabled={true} value={list?.followUp.menstrual_history} />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <Form.Item label="Other Findings" name="other_findings">
          <Input placeholder="Other Findings" disabled={true} value={list?.followUp.other_findings} />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <Form.Item label="Investigation" name="investigation">
          <Input placeholder="Investigation" disabled={true} value={list?.followUp.investigation} />
        </Form.Item>
      </Col>

      <Col xs={24} md={12}>
        <Form.Item label="Treatment" name="treatment">
          <Input placeholder="Treatment" disabled={true} value={list?.followUp.treatment} />
        </Form.Item>
      </Col>
    </Row>
   
  </Form>   
    </div>
}
                <div className="view-inner-cls float-right">
                  <Link className="ant-btn ant-btn-primary" to={`/appointment/`}>Back</Link>
                </div>

              </div>
            }

          </Col>
        </Row>
      </Card>
    </>
  );

}

export default View;